import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  handlePageLoading,
  handleSaveUserData,
} from '../actions';
import { User, userData } from '../constants/actionTypes';


const initialState: userData = {
  user: {},
  pageLoading: false,
};

const userReducer = createReducer(initialState, (builder: any) => {
  builder
    .addCase(handleSaveUserData, (state: userData, action: PayloadAction<User>) => {
      console.log('user', action.payload);
      state.user = action.payload;
    })
    .addCase(handlePageLoading, (state: userData, action: PayloadAction<boolean>) => {
      state.pageLoading = action.payload;
    });

});

export default userReducer;
