import React, { useState } from 'react';
import { ReactComponent as EmailIcon } from '../../Assets/Icons/Emailiconwhite.svg';
import { Link } from 'react-router-dom';
import { ReactComponent as HomeAbstract } from '../../Assets/Images/homeabstract.svg';
import { useDispatch, useSelector } from 'react-redux';
import { handleForgotPassword } from '../../Storeredux/actions';
import CustomButton from '../Form/CustomButton';
import { RootState } from '../../Storeredux/reducers/rootReducer';
import { Auth } from '../../Storeredux/constants/actionTypes';

function ForgotPasswordForm() {
  const dispatch = useDispatch();
  const AuthState = useSelector((state: RootState) => state.auth as Auth);
  const { loading } = AuthState;
  const [data, setData] = useState({
    email: '',
  });

  const submit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    console.log(data);
    dispatch(handleForgotPassword(data));
  };
  return (
    <div>
      <div className='relative overflow-hidden' style={{ height: '90vh' }}>
        <div className='w-full hidden md:flex'>
          <HomeAbstract className='abstract'/>
        </div>
        <div className='absolute top-0 h-screen flex flex-col items-center w-full' style={{ backgroundColor: '#0000000D' }}>
          <div className='bg-white rounded-xl md:w-6/12 lg:w-5/12 xl:w-4/12 overflow-hidden border border-input-color my-12'
            style={{ boxShadow: '0px 2px 8px 2px #8C8C8C1A' }}>
            <form className='w-full p-7' onSubmit={submit} >
              <div className=''>
                <h3 className='font-semibold text-xl'>
                  Relax, we have you covered.
                </h3>
                <p className='text-custom-darkgreen text-xs py-2'>Provide your email address to reset the password.</p>
              </div>
              <div className='pt-6 pb-4'>
                <label className='font-semibold text-sm'>
                   Email
                </label>
                <div className="relative">
                  <EmailIcon
                    className="absolute w-6 h-6 text-gray-500 left-2 top-1/2 transform -translate-y-1/2"/>
                  <input required onChange={(e) => setData({ ...data, email: e.target.value })}
                    className="focus-visible:outline-0 w-full p-3 pl-10 border border-input-color rounded-custom"
                    type="text" placeholder="Enter your email"/>
                </div>
              </div>
              <CustomButton
                isLoading={loading}
                children={'Send password reset email'}
                className='text-sm md:text-md w-full pt-3 mt-3.5 bg-custom-darkgreen text-white font-semibold rounded-custom py-2'
                disabled={!data.email}
              />
              <div className='text-center py-3 text-sm'>
                <p>
                   Remember account password?
                  <Link to='/login' className='font-semibold text-custom-green'>Login</Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordForm;
