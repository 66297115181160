import React from 'react';
import Navbar from './Navbar';
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../../Pages/Dashboard/Dashboard';
import Footer from './Footer';
import Portfolio from '../../Pages/Dashboard/Portfolio';
import Viewproperty from '../../Pages/Dashboard/Viewproperty';
import Listing from '../../Pages/Dashboard/Listing';
import Wallet from '../../Pages/Dashboard/Wallet';
import Setting from '../../Pages/Dashboard/Setting';
import ListerDashboard from '../../Pages/Dashboard/Lister/Dashboard';
import ListerPortfolio from '../../Pages/Dashboard/Lister/Portfolio';
import AddProperty from '../../Pages/Dashboard/AddProperty';
import MyProperties from '../../Pages/Dashboard/MyProperties';

function Layout() {
  const state = {
    userType: 'liste',
  };
  return (
    <div className='relative h-screen flex flex-col'>
      <Navbar />
      <div className='bg-custom-white flex-grow overflow-y-auto'>
        <Routes>
          <Route path='/dashboard' element={state.userType === 'lister' ? <ListerDashboard/> : <Dashboard />} />
          <Route path='/properties' element={state.userType === 'lister' ? <ListerPortfolio/> : <Portfolio />} />
          <Route path='/myProperties' element={state.userType === 'lister' ? <ListerPortfolio/> : <MyProperties />} />
          <Route path='/properties/:id' element={<Viewproperty />} />
          <Route path='/listing' element={<Listing />} />
          <Route path='/wallet' element={<Wallet />} />
          <Route path='/setting' element={<Setting />} />
          <Route path='/addproperty' element={<AddProperty />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
