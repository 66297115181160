import React, { Fragment } from 'react';
import '../../Stylesheets/propertyTab.css';
import { Icon } from '../../Services/constant/serviceType';

interface TabProps {
  id: string;
  label: string;
  icon: React.FunctionComponent<Icon>;
}

interface TabsProps {
  tabs: TabProps[];
  activeTabIndex: number;
  onTabChange: (index: number) => void;
}

const Tabs = ({ tabs, activeTabIndex, onTabChange }: TabsProps) => (
    <div className="tabsProperty border border-custom-lemongreen rounded !bg-[#C8DAD3] w-fit">
        {tabs.map((tab, index) => (
            <Fragment key={index}>
                <input type="radio" id={tab.id} name="tabs" />
                <label
                    className={`tabProperty text-sm gap-2 font-semibold ${activeTabIndex === 0 && tab.id === 'rent' ? 'text-custom-lemongreen' : activeTabIndex === 1 && tab.id === 'coOwn' ? 'text-custom-lemongreen' : ''}`}
                    htmlFor={tab.id}
                    onClick={() => onTabChange(index)}
                >
                    <tab.icon size={20} color={activeTabIndex === 0 && tab.id === 'rent' ? '#20996B' : activeTabIndex === 0 && tab.id === 'buy' ? '#20996B' : activeTabIndex === 1 && tab.id === 'coOwn' ? '#20996B' : '#192A3E'} />
                    {tab.label}
                </label>
            </Fragment>
        ))}
        <span className="gliderProperty" style={{ transform: `translateX(${activeTabIndex * 100}%)` }} />
    </div>
);

export default Tabs;
