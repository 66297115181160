import { Icon } from '../../Services/constant/serviceType';

export const BuyIcon = ({ size, color }: Icon) => {
  return (
      <>
          <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M14.1667 17.0827H5.83341C3.33341 17.0827 1.66675 15.8327 1.66675 12.916V7.08268C1.66675 4.16602 3.33341 2.91602 5.83341 2.91602H14.1667C16.6667 2.91602 18.3334 4.16602 18.3334 7.08268V12.916C18.3334 15.8327 16.6667 17.0827 14.1667 17.0827Z"
                  stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"
                  strokeLinejoin="round"/>
              <path
                  d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
                  stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"
                  strokeLinejoin="round"/>
              <path d="M4.58325 7.91602V12.0827" stroke={color} strokeWidth="2" strokeMiterlimit="10"
                    strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M15.4167 7.91602V12.0827" stroke={color} strokeWidth="2" strokeMiterlimit="10"
                    strokeLinecap="round" strokeLinejoin="round"/>
          </svg>

      </>
  );
};
