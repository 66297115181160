import React, { useEffect, useState } from 'react';
import InputField from '../Form/InputField';
import SelectField from '../Form/SelectField';
import { useSelector } from 'react-redux';
import { RootState } from '../../Storeredux/reducers/rootReducer';
import { userData } from '../../Storeredux/constants/actionTypes';
import { useUserData } from '../../Services/hooks/User';
import { UserData } from '../../Services/constant/serviceType';
import { areAllKeysFilled } from '../../Utils/formValidator';
import CustomButton from '../Form/CustomButton';

function PersonalInformation() {
  const UserState = useSelector((state: RootState) => state.user as userData);
  const { user } = UserState;
  const { getUserInfo, loading, updateUserData } = useUserData();
  console.log(user);
  const [userStateData, setUserStateData] = useState<UserData>({
    first_name: '',
    last_name: '',
    maiden_name: '',
    email: '',
    gender: '',
    in_diaspora: user.in_diaspora,
    dob: user.dob,
    country_code: user.country_code,
    phone: '',
  });
  console.log('userStateData', userStateData);
  const [readOnly, setReadOnly] = useState(true);
  const gender = [
    {
      value: 'male',
      label: 'Male',
    },
    {
      value: 'female',
      label: 'Female',
    },
  ];
  const diaspora = [
    {
      value: 1,
      label: 'Yes',
    },
    {
      value: 0,
      label: 'No',
    },
  ];
  const isFormComplete = areAllKeysFilled(userStateData);
  const onChange = (e: any, fieldKey: string) => {
    console.log('next', e.target.value, fieldKey);
    setUserStateData((prevData) => ({
      ...prevData,
      [fieldKey]: e.target.value,
    }));
  };

  const onSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    console.log(user);
    let info = await updateUserData(userStateData, user.id);
    console.log('iii', info);
    // let info = await getUserInfo(user.id);
    info =  info.response;
    console.log('jjj', info);
    setUserStateData({
      first_name: info.firstname  || '',
      last_name: info.lastname || '',
      maiden_name: info.maiden_name || '',
      email: info.email || '',
      gender: info.gender || '',
      in_diaspora: info.in_diaspora || false,
      dob: info.dob || false,
      country_code: info.country_code || '',
      phone: info.phone || false,
    });
    setReadOnly(true);
  };

  useEffect(() => {
    getUserInfo(user.id);
    if (user) {
      setUserStateData({
        first_name: user.firstname  || '',
        last_name: user.lastname || '',
        maiden_name: user.maiden_name || '',
        email: user.email || '',
        gender: user.gender || '',
        in_diaspora: user.in_diaspora || false,
        dob: user.dob || false,
        country_code: user.country_code || '',
        phone: user.phone || false,
      });
    }
  }, []);
  return (
      <div className='p-4 md:p-10 flex flex-col gap-10'>
          <form onSubmit={onSubmit} className=' flex flex-col gap-10'>
              <div className='flex w-full gap-5'>
                  <InputField name='First Name' fieldKey={'first_name'} type='text' value={userStateData.first_name}
                              readOnly={readOnly} onChange={onChange} className='w-1/2'/>
                  <InputField name='Last Name' fieldKey={'last_name'} type='text' value={userStateData.last_name}
                              readOnly={readOnly} onChange={onChange} className='w-1/2'/>
              </div>
              <div className='flex w-full gap-5'>
                  <InputField name='Middle Name' fieldKey={'maiden_name'} value={userStateData.maiden_name}
                              readOnly={readOnly} onChange={onChange} type='text' className='w-1/2'/>
                  <InputField name='Email Address' fieldKey={'email'} type='email' value={userStateData.email}
                              readOnly={readOnly} onChange={onChange} className='w-1/2'/>
              </div>
              <div className='flex w-full gap-5'>
                  <SelectField name='Gender' fieldKey={'gender'} option={gender} onChange={onChange}
                               value={userStateData.gender}
                               readOnly={readOnly} className='w-1/2'/>
                  <SelectField name='Are you in diaspora' fieldKey={'in_diaspora'} option={diaspora}
                               readOnly={readOnly} value={userStateData.in_diaspora} onChange={onChange}
                               className='w-1/2'/>
              </div>
              <div className='flex w-full gap-5'>
                  <InputField name='Date Of Birth' fieldKey={'dob'} type='date' value={userStateData.dob}
                              readOnly={readOnly} onChange={onChange} className='w-1/2'/>
                  <SelectField name='Country' fieldKey={'country_code'} option={diaspora}
                               value={userStateData.country_code}
                               readOnly={readOnly} onChange={onChange} className='w-1/2'/>
              </div>
              <div className='flex w-full gap-5'>
                  <InputField name='Phone Number' fieldKey={'phone'} type='tel' value={userStateData.phone}
                              readOnly={readOnly} onChange={onChange} className='w-1/2'/>
              </div>

              <div className='flex w-full gap-5'>
                  {
                      readOnly ?
                          <button type={'button'} onClick={() => setReadOnly(false)}
                                  className='bg-custom-midnightgreen text-white py-2 px-12 font-semibold rounded-custom border-0'>
                              Edit Changes
                          </button> :
                          <CustomButton
                              isLoading={loading}
                              disabled={!isFormComplete}
                              children={'Save Changes'}
                              className='bg-custom-midnightgreen text-white py-2 px-12 font-semibold rounded-custom border-0'
                          />
                  }
              </div>
          </form>

          <div className='w-full py-5 px-4' style={{ backgroundColor: '#F0EEEE' }}>
              <h1 className='text-2xl font-bold uppercase'>
                  Identification
              </h1>
          </div>

          <div className='flex w-full gap-5'>
              <SelectField name='Id Type' option={gender} className='w-full'/>
          </div>
          <div className='flex w-full gap-5'>
              <InputField name='Id Number' type='text' className='w-full'/>
          </div>
          <div className='flex w-full gap-5'>
              <button className='bg-custom-midnightgreen text-white py-2 px-12 font-semibold rounded-custom border-0'>
                  Save Changes
              </button>

              {
                  readOnly ?
                      <button type={'button'} onClick={() => setReadOnly(false)}
                              className='bg-custom-midnightgreen text-white py-2 px-12 font-semibold rounded-custom border-0'>
                          Edit Changes
                      </button> :
                      <CustomButton
                          isLoading={loading}
                          disabled={!isFormComplete}
                          children={'Save Changes'}
                          className='bg-custom-midnightgreen text-white py-2 px-12 font-semibold rounded-custom border-0'
                      />
              }
          </div>
      </div>
  );
}

export default PersonalInformation;
