import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Tabs from '../Tab/FeaturedPropertyTab';
// import { RentIcon } from '../../Assets/Icons/rentIcon';
import { BuyIcon } from '../../Assets/Icons/buyIcon';
import { CoOwnIcon } from '../../Assets/Icons/coOwnIcon';
import { ReactComponent as Bed } from '../../Assets/Icons/bedcardIcon.svg';
import { ReactComponent as Shower } from '../../Assets/Icons/ShowerIcon.svg';
import { ReactComponent as Toilet } from '../../Assets/Icons/toiletIcon.svg';
import { formatCurrency } from '../../Utils/currencyFormat';
import { useLandingPage } from '../../Services/hooks/landingpage';
function FeaturedProject() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const { GetHomeProperty, property } = useLandingPage();
  const tabs = [
    // { id: 'rent', label: 'Rent', icon: RentIcon },
    { id: 'buy', label: 'Buy', icon: BuyIcon },
    { id: 'coOwn', label: 'Co-own', icon: CoOwnIcon },
  ];
  const handleTabChange = (index: number) => {
    console.log(index);
    setActiveTabIndex(index);
    console.log(`Selected Tab: ${tabs[index].id}`);
    const tabLabel = tabs[index].id;
    console.log(`Selected Tab: ${tabLabel}`);
  };

  useEffect(() => {
    GetHomeProperty();
  }, []);
  return (
    <div>
      <div>
        <div className='text-center'>
          <h1 className='text-2xl md:text-3xl lg:text-4xl font-bold py-2'>
            Featured properties
          </h1>
          <div className={'flex justify-center py-2'}>
            <Tabs tabs={tabs} activeTabIndex={activeTabIndex} onTabChange={handleTabChange}/>
          </div>
        </div>
        <div className='featuredProperty lg:w-10/12 mx-auto'>
          {property.slice(0, 8).map((investment: any, index) => {
            return (
                <div key={index}
                     className='rounded-3xl overflow-hidden xl:w-[285px] bg-white border border-custom-grey'>
                  <div className='relative'>
                    <img src={investment?.property?.thumbnail} alt='investment' className='w-full h-44 bg-green-200'/>
                    <span
                        className={`absolute uppercase bg-white top-2 font-semibold left-2 rounded-full text-xs py-1 px-2.5 ${investment.property.available_slots === 0 ? 'text-red-500' : 'text-green-600'}`}>
                          {investment?.property.available_slots === 0 ? 'Sold Out' : 'Active'}
                        </span>
                  </div>
                  <div className='p-3'>
                    <h1 className='py-2'>{investment.property.details.property_title}</h1>
                    <div className={'flex gap-2 py-2'}>
                    <span
                        className={'bg-custom-grey flex justify-between gap-3 py-1 px-2.5 w-fit rounded-3xl items-center text-xs'}>
                      <span>
                        <Bed/>
                      </span>
                      <span>{investment.property.details.rooms_no}</span>
                    </span>
                      <span
                          className={'bg-custom-grey flex justify-between gap-3 py-1 px-2.5 w-fit rounded-3xl items-center text-xs'}>
                      <span>
                        <Shower/>
                      </span>
                      <span>{investment.property.details.showers}</span>
                    </span>
                      <span
                          className={'bg-custom-grey flex justify-between gap-3 py-1 px-2.5 w-fit rounded-3xl items-center text-xs'}>
                      <span>
                        <Toilet/>
                      </span>
                      <span>{investment.property.details.toilets_no}</span>
                    </span>
                    </div>
                    <p className='font-bold text-lg pb-2.5'>{formatCurrency(investment.property.total_investment_amount)}</p>
                    <p className='text-xs pb-2.5 text-custom'>{investment?.property?.get_address?.title}</p>
                    <div className='flex justify-between text-xs pb-1'>
                      <span>No Of Slots Available:</span>
                      <span
                          className={`font-semibold ${investment?.property.available_slots === 0 ? 'text-red-500' : 'text-green-600'}`}>
                        {investment.property.available_slots === 0 ? 'Sold Out' : `${investment.property.available_slots} ${investment?.property?.available_slots === 1 ? 'Slot' : 'Slots'}`}
                      </span>
                    </div>
                    <div className='flex justify-between gap-1 pb-1.5'>
                      {Array.from({ length: investment.property.available_slots }).map((_, number) => (
                          <hr key={number}
                              className={`h-2 w-8 rounded-full ${number < (investment.property.total_slots - investment.property.available_slots) ? 'bg-custom-darkgreen' : 'bg-custom'}`}/>
                      ))}
                    </div>
                    <div className='flex justify-between text-xs pb-2.5'>
                      <span>Price Per Slot:</span>
                      <span
                          className={'font-semibold'}>{formatCurrency(investment.property.total_investment_amount)}</span>
                    </div>
                    <div className='flex justify-end'>
                      <Link to={`/app/properties/${investment.id}`}
                            className='bg-custom-midnightgreen px-7 py-1 rounded-full text-white text-sm'>
                        View
                      </Link>
                    </div>
                  </div>
                </div>
            );
          })}


          {/*{Investment.map((investment, index) => {*/}
          {/*  return (*/}
          {/*      <div key={index}*/}
          {/*           className='rounded-3xl overflow-hidden xl:w-[285px] bg-white border border-custom-grey'>*/}
          {/*        <div className='relative'>*/}
          {/*          <img src={propertyImage} alt='investment' className='w-full'/>*/}
          {/*          <span*/}
          {/*              className={`absolute uppercase bg-white top-2 font-semibold left-2 rounded-full text-xs py-1 px-2.5 ${investment.noOfSlotAvailable === 0 ? 'text-red-500' : 'text-green-600'}`}>*/}
          {/*          {investment.noOfSlotAvailable === 0 ? 'Sold Out' : 'Active'}*/}
          {/*        </span>*/}
          {/*        </div>*/}
          {/*        <div className='p-3'>*/}
          {/*          <h1 className='py-2'>{investment.title}</h1>*/}
          {/*          <p className='font-bold text-lg pb-2.5'>{investment.totalPrice}</p>*/}
          {/*          <div className='flex justify-between text-xs pb-1'>*/}
          {/*            <span>No Of Slot Available:</span>*/}
          {/*            <span*/}
          {/*                className={`font-semibold ${investment.noOfSlotAvailable === 0 ? 'text-red-500' : 'text-green-600'}`}>*/}
          {/*            {investment.noOfSlotAvailable === 0 ? 'Sold Out' : `${investment.noOfSlotAvailable} Slot Available`}*/}
          {/*          </span>*/}
          {/*          </div>*/}
          {/*          <div className='flex justify-between gap-1 pb-1.5'>*/}
          {/*            {Array.from({ length: investment.totalNumberOfSlot }).map((_, number) => (*/}
          {/*                <hr key={number} className={`h-2 w-8 rounded-full ${number < (investment.totalNumberOfSlot - investment.noOfSlotAvailable) ? 'bg-custom-darkgreen' : 'bg-custom'}`}/>*/}
          {/*            ))}*/}
          {/*          </div>*/}
          {/*          <div className='flex justify-between text-xs pb-2.5'>*/}
          {/*            <span>Price Per Slot:</span>*/}
          {/*            <span className={'font-semibold'}>{investment.priceOfSlot}</span>*/}
          {/*          </div>*/}
          {/*          <div className='flex justify-between text-xs pb-6'>*/}
          {/*            <span>Annual Earnings:</span>*/}
          {/*            <span className={'font-semibold'}>{investment.annualEarning}</span>*/}
          {/*          </div>*/}
          {/*          <div className='flex justify-end'>*/}
          {/*            <Link to={`/app/property/${investment.id}`}*/}
          {/*                  className='bg-custom-midnightgreen px-7 py-1 rounded-full text-white text-sm'>*/}
          {/*              View*/}
          {/*            </Link>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*  );*/}
          {/*})}*/}

        </div>
        <div className='flex lg:justify-end justify-end md:justify-end px-2 md:px-2 w-full lg:w-10/12 mx-auto pb-12 items-center'>
          {/*<p className=' hidden text-xs md:hidden lg:block'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod*/}
          {/*    tempor incididunt ut labore et dolore magna aliqua.</p>*/}
          <Link to={'property'} className='block text-center border border-black lg:w-2/12 md:w-fit font-semibold bg-white text-sm rounded-full py-2 px-4'>See More</Link>
        </div>
      </div>
    </div>
  );
}

export default FeaturedProject;
