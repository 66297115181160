import { getFeaturedProperty, postContactUs } from '../services/userService';
import { useRef, useState } from 'react';
import { ContactUs } from '../constant/serviceType';
import { useToast } from '../../Store/NotificationContext';

export const useLandingPage = () => {
  const [property, setProperty] = useState<[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const didFetch = useRef<boolean>(false);
  const { showToast } = useToast();
  const ContactUsRequest = async (payload: ContactUs) => {
    if (didFetch.current) return;
    let userResponse = null;
    setLoading(true);
    try {
      const response = await postContactUs(payload);
      userResponse = response.data.status;
      if (userResponse) {
        setLoading(false);
        showToast('Success', response.data.message, 'success');
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return { success: true };
      }
    } catch (e: any) {
      // console.log(e.response.data.message);
      setLoading(false);
      showToast('Error', e.response.data.message, 'error');
      return { success: false, error: e.response.data.message };
    }

    return userResponse;
  };
  const GetHomeProperty = async () => {
    if (didFetch.current) return;
    let userResponse = null;
    setLoading(true);
    try {
      const response = await getFeaturedProperty();
      userResponse = response?.data?.data;
      if (userResponse) {
        setLoading(false);
        setProperty(response?.data?.data);
        return { success: true };
      }
    } catch (e: any) {
      setLoading(false);
      return { success: false, error: e.response.data.message };
    }

    return userResponse;
  };

  return { loading, ContactUsRequest, GetHomeProperty, property };
};
