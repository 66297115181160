import React, { useRef, Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from '../../Assets/Icons/Logo.svg';
import { ReactComponent as Flag } from '../../Assets/Icons/flag.svg';
import { ReactComponent as Notification } from '../../Assets/Icons/notification.svg';
import { Element3, Wallet3, Briefcase, User, Setting2, ArrowDown2 } from 'iconsax-react';
import { Menu, Transition } from '@headlessui/react';
import { handleLogoutRequest } from '../../Storeredux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../Storeredux/reducers/rootReducer';
import { userData } from '../../Storeredux/constants/actionTypes';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}
function Navbar() {
  const UserState = useSelector((state: RootState) => state.user as userData);
  const { user } = UserState;
  const location = useLocation();
  const dispatch = useDispatch();
  let pageName = location.pathname;
  pageName = pageName.slice(5);
  const Dashboard = [
    {
      Name: 'Dashboard',
      pathname: 'dashboard',
      icon: Element3,
    },
    {
      Name: 'Properties',
      pathname: 'properties',
      icon: Briefcase,
    },
    // {
    //     Name: 'Listing',
    //     pathname: 'listing',
    //     icon: HambergerMenu
    // },
    {
      Name: 'My Properties',
      pathname: 'myProperties',
      icon: Wallet3,
    },
    {
      Name: 'Wallet',
      pathname: 'wallet',
      icon: Wallet3,
    },
  ];
  const size = useRef(18);

  const sizeSettings = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth <= 390) {
      size.current = 12;

    }
  };
  sizeSettings();

  return (
    <div className='navbarshadow'>
      <div className='lg:w-11/12 mx-auto'>
        <div className=' flex justify-between'>
          <div className='flex gap-5 justify-center items-center'>
            <Link to='/'>
              <Logo className='w-full md:w-7/12 lg:w-full md:w-full h-10'/>
            </Link>
          </div>
          <div className='flex gap-10 justify-center items-center'>
            <div>
              <Flag className='w-7/12 lg:w-full md:w-full hidden md:block'/>
            </div>
            <Link to='setting'>
              <Setting2 size="22" color="#000000"/>
            </Link>
            <div>
              <Notification className='w-full lg:w-full md:w-full'/>
            </div>
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button
                  className="border-none inline-flex w-full p-1.5 md:p-3 gap-2 items-center bg-custom-light-grey justify-center gap-x-1.5 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-50">
                  <div
                    className='bg-white w-4 h-4 md:w-8 md:h-8 flex justify-center items-center rounded-full'>
                    <User variant="Bold" color='#F3F3F9' className='self-center'/>
                  </div>
                  <div>
                    <h5 className='font-semibold text-xs md:text-xs'>
                      {user.fullname}
                    </h5>
                    {/*<p className='text-xs md:text-xs text-custom text-left'>Founder</p>*/}
                  </div>
                  <ArrowDown2 size={24} className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true"/>
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute overflow-hidden right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="">
                    <Menu.Item>
                      {({ active }) => (
                        <Link to='/app/setting'
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm',
                          )}
                        >
                          Account settings
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="submit"
                          onClick={() => dispatch(handleLogoutRequest())}
                          className={classNames(
                            active ? 'bg-red-500 text-white' : 'text-gray-700',
                            'block w-full px-4 py-2 text-left text-sm',
                          )}
                        >
                         Sign out
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
        <div>
          <ul className='flex p-3 px-6 gap-2.5 md:gap-10 items-center justify-center md:justify-start'>
            {
              Dashboard.map((dashboard, index) => {
                return (
                  <Link to={`/app/${dashboard.pathname}`} key={index}
                    className={`flex gap-1 text-xs md:text-sm items-center font-semibold cursor-pointer ${pageName === dashboard.pathname ? 'bg-custom-midnightgreen rounded-full p-2 md:px-4 px-2 text-white' : 'text-custom'}`}>
                    <dashboard.icon size={size.current} className={'hidden sm:block'}
                      color={pageName === dashboard.pathname ? '#ffffff' : '#6D7080'}/>
                    <span
                      className={`${pageName === dashboard.pathname ? 'text-white font-semibold' : ''}`}>{dashboard.Name}</span>
                  </Link>
                );
              })
            }
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
