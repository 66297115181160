export const REGISTER = '/auth/register';
export const LOGIN = '/auth/login';
export const FORGOT_PASSWORD = '/user/forgot-password';
export const GET_DASHBOARD = '/dashboard';
export const GET_PROPERTY = '/property';
// export const GET_WALLET = '/user/dashboard';
export const FUND_WALLET = '/user/dashboard';
export const NEXT_OF_KIN = '/next-of-kin';
export const USER_DATA = '/user';
export const GET_INVESTMENT_INFO = '/invest';
export const PAYMENT_REQUEST = '/invest/pay';
export const CONTACT_US = '/contact-us';
export const VERIFY_PAYMENT = '/invest/verify';
export const GET_HOME_PROPERTY = 'featured-properties';
export const GET_USER_PROPERTY = 'user/properties';
export const GET_WALLET = '/user/wallet';
export const GET_PAYMENT_HISTORY = '/user/transactions/wallet';
export const PAY_WITH_WALLET = '/invest/wallet/pay';
export const CHECK_PAYMENT = '/deposit';

