import React, { useEffect, useRef, useState } from 'react';
import { ArrowCircleRight, ArrowLeft } from 'iconsax-react';
// import propertyImage from '../../Assets/Images/viewpropertyImage.png';
import { ReactComponent as Bath } from '../../Assets/Icons/bath.svg';
import { ReactComponent as Bed } from '../../Assets/Icons/bed.svg';
import { ReactComponent as Wifi } from '../../Assets/Icons/wifi.svg';
import { ReactComponent as Share } from '../../Assets/Icons/share.svg';
import { ReactComponent as Expand } from '../../Assets/Icons/expand.svg';
// import { ReactComponent as Rating } from '../../Assets/Icons/starrating.svg';
import { ReactComponent as Mark } from '../../Assets/Icons/mark.svg';
import Placeholder from '../../Assets/Images/placeholder.png';
import Placeholder2 from '../../Assets/Images/placeholder2.png';
import Placeholder3 from '../../Assets/Images/placeholder3.png';
import Placeholder4 from '../../Assets/Images/placeholder4.png';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useLocation, useNavigate } from 'react-router-dom';
import BuyProperty from '../../Components/Wallet/BuyProperty';
import { useSingleProperty } from '../../Services/hooks/SingleProperty';
import { PageLoader } from '../../Components/Loader/pageLoader';
import { formatCurrency } from '../../Utils/currencyFormat';
import { usePayment } from '../../Services/hooks/payment';
import { dateFormat } from '../../Utils/dateFormat';

function Viewproperty() {
  const { getInvestmentInfo  } = usePayment();
  const datata = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  let pageName = location.pathname;
  pageName = pageName.split('/').pop() || '';
  const { getSinglePropertyData, loading: pageLoading, propertyDetails } = useSingleProperty();
  console.log(propertyDetails?.property);
  const image = 4;
  const [imageContainer, setImageContainer] = useState<{ id: number; image: any }[]>([
    {
      id: 1,
      image: Placeholder,
    },
    {
      id: 2,
      image: Placeholder2,
    },
    {
      id: 3,
      image: Placeholder3,
    },
    {
      id: 4,
      image: Placeholder,
    },
    {
      id: 5,
      image: Placeholder2,
    },
    {
      id: 6,
      image: Placeholder4,
    },
  ]);
  const handleClick = () => {
    const updatedImageContainer = [...imageContainer];
    const firstChild = updatedImageContainer.shift();
    if (firstChild) {
      updatedImageContainer.push(firstChild);
      setImageContainer(updatedImageContainer);
      console.log(imageContainer);
    }
  };

  const containerStyle = {
    width: '100%',
    height: '220px',
  };

  const center = {
    lat: -34.397,
    lng: 150.644,
  };

  const [buy, setBuy] = useState(false);
  const buySlot = async () => {
    const response = await getInvestmentInfo(pageName);
    console.log(response);
    if (response) {
      datata.current = response;
      setBuy(true);
    }
  };

  useEffect(() => {
    getSinglePropertyData(pageName);
  }, []);
  return (
    <div className='py-10'>
      {!buy ? <div className='w-11/12 mx-auto'>
        <div className='flex items-center gap-2 py-4 cursor-pointer'>
          <ArrowLeft size="32" color="#000000" onClick={() => navigate(-1)}/>
          <p className='font-semibold'>Property Detail</p>
        </div>
        <div className='rounded-3xl bg-white mx-auto px-3 py-6 md:px-5 lg:px-10 xl:px-16 flex justify-between'>
          {
            pageLoading ?
                <div className='gap-7 w-fit justify-center mx-auto mt-7 flex flex-wrap h-52'>
                  <PageLoader/>
                </div>
              :
                <>
                  <div className='md:w-3/12  hidden md:block'>
                    <h1 className='font-bold pb-3 text-xl '>
                      Price
                    </h1>
                    <div
                        className={` rounded-2xl w-full text-center py-4 ${propertyDetails?.property?.available_slots ? 'bg-custom-darkgreen' : 'bg-red-600'}`}>
                      <span className='text-white md:text-sm text-xs font-semibold'>Property Value</span>
                      <p className='font-bold text-white text-sm md:text-xl lg:text-2xl lg:py-4 xl:py-6'>{formatCurrency(propertyDetails?.property?.total_investment_amount)}</p>
                      {/*<p className='text-white font-semibold text-xxs lg:text-sm'>Annual Return:₦6,000.000.00</p>*/}
                    </div>
                    <div className='pt-5 md:block hidden'>
                      <h1 className='font-bold text-sm md:text-xl py-5'>Co-Owners History</h1>
                      {
                        propertyDetails?.investors.length === 0 ?
                            <div>
                              <p className={'text-custom text-center'}>No previous Owner</p>
                            </div>
                          :
                            <div>
                              {
                                propertyDetails?.investors.map((history: any, index: number) => {
                                  return (
                                      <div key={index} className='flex gap-4'>
                                        <div>
                                        <span className='block md:h-8 md:w-7 h-4 w-4 bg-custom-white rounded-xl'>
                                        </span>
                                          <div
                                              className={`bg-white ${index + 1 === history.length ? 'hidden' : ''}`}>
                                            {Array.from({ length: 5 }).map((_, number) => (
                                                <hr key={number}
                                                    className={'h-0.5 w-1.5 mx-auto rounded border-0 horizontal-to-vertical my-3 bg-custom-white '}/>
                                            ))}
                                          </div>
                                        </div>
                                        <div>
                                          <h1 className='font-semibold text-sm lg:text-lg'>{history?.investor?.fullname}</h1>
                                          <p className='text-custom text-xxs lg:text-xs'>Purchased At {dateFormat(history?.slots[0]?.created_at)}</p>
                                          <p className='text-custom text-xxs lg:text-xs'>Slot ID: {history?.slots[0]?.slots}</p>
                                          {/*<p className='flex gap-1.5'>*/}
                                          {/*  {Array.from({ length: 5 }).map((_, rate) => (*/}
                                          {/*      <Rating key={rate}/>*/}
                                          {/*  ))}*/}
                                          {/*</p>*/}
                                        </div>
                                      </div>
                                  );
                                })
                              }
                            </div>

                      }
                    </div>
                    <button onClick={buySlot}
                            className={`${propertyDetails?.property?.available_slots === 0 ? 'hidden' : ''} bg-custom-darkgreen text-sm w-full border-full py-1.5 text-white font-semibold my-5 rounded-full`}
                            style={{ boxShadow: '0px 4px 4px 0px #00000040' }}>
                      Buy Slot
                    </button>
                  </div>
                  <div className='md:w-8/12 w-full'>
                    <div className='relative'>
                      <div className='relative'>
                        <img src={propertyDetails?.property?.thumbnail} alt='property' className={'w-full bg-green-200'} style={{ height: '30rem' }}/>
                        <span
                            className={`${propertyDetails?.property?.available_slots === 0 ? 'text-red-600' : 'text-green-600'} absolute uppercase bg-white top-2 font-semibold right-3 rounded-full text-xxs md:text-xs py-1 px-1.5 md:py-2 md:px-2.5 property-badge`}>
                          {propertyDetails?.property?.available_slots === 0 ? 'Sold Out' : 'Active'}
                        </span>
                      </div>
                      <div className='absolute bottom-0 flex px-4 w-full justify-between'>
                        <div>
                          <h1 className='text-white text-lg lg:text-2xl font-bold py-4'>
                            {propertyDetails?.property?.details?.property_title}
                          </h1>
                          <div className='flex gap-2 md:gap-3.5 pb-3 justify-between'>
                            <div className='flex gap-2 items-center'>
                      <span>
                        <Bed/>
                      </span>
                              <span className='text-white text-xxs md:text-xs font-semibold'>
                                            {propertyDetails?.property?.details.rooms_no} Bedroom
                      </span>
                            </div>
                            <div className='flex gap-2 items-center'>
                      <span>
                        <Bath/>
                      </span>
                              <span className='text-white text-xxs md:text-xs font-semibold'>
                                            {propertyDetails?.property?.details?.showers} Bathroom
                      </span>
                            </div>
                            <div className='flex gap-2 items-center'>
                      <span>
                        <Wifi/>
                      </span>
                              <span className='text-white text-xxs md:text-xs font-semibold'>
                                            Wifi Available
                      </span>
                            </div>
                          </div>
                        </div>
                        <div className='hidden md:flex flex-col gap-6 items-end -mt-11'>
                          <Share/>
                          <Expand/>
                          <div>
                            <p className='text-end text-white text-sm'>1 of 4</p>
                            <div className='flex justify-between gap-1 pb-1.5'>
                              {Array.from({ length: image }).map((_, index) => (
                                  <hr key={index}
                                      className={'h-2 w-8 rounded-full bg-custom-white opacity-25'}/>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='flex md:hidden gap-x-3.5 justify-between items-center'>
                      <div className=''>
                        <p className='flex items-center py-2'>
                          <span className='text-xs'>Property Value :</span>
                          <span className='text-xs font-bold'>{formatCurrency(propertyDetails?.property?.total_investment_amount)}</span>
                        </p>
                        {/*<p className='flex'>*/}
                        {/*  <span className='text-xxs'>Annual Return :</span>*/}
                        {/*  <span className='text-xxs font-bold'>₦6,000.000.00</span>*/}
                        {/*</p>*/}
                      </div>
                      <button onClick={buySlot}
                              className={` ${propertyDetails?.property?.available_slots === 0 ? 'hidden' : ''} bg-blue-600 text-sm w-fit border-full px-4 py-1.5 text-white font-semibold my-5 rounded-full`}
                              style={{ boxShadow: '0px 4px 4px 0px #00000040' }}>
                        Buy Slot
                      </button>
                    </div>
                    <div className='py-10 pt-5'>
                      <h1 className='font-bold pb-2'>Description</h1>
                      <p
                          className='text-xxs md:text-xs lg:text-sm'
                          dangerouslySetInnerHTML={{ __html: propertyDetails?.property?.details?.details }}
                      />
                    </div>
                    <div className='py-10'>
                      <h1 className='font-bold pb-4'>Gallery</h1>
                      <div className='relative'>
                        <div className='flex gap-2.5 overflow-hidden img'>
                          {
                            propertyDetails?.property?.image?.map((imagePath: any, index: number) => {
                              return (
                                  <img key={index} src={imagePath.image} alt='placeholder'
                                       className='max-w-50% max-h-50% md:max-h-32 md:max-w-32 rounded-xl'/>
                              );
                            })
                          }
                        </div>
                        <div className='absolute right-2 top-1/2'>
                          <ArrowCircleRight size="32" onClick={handleClick} color="#FFFFFF" variant="Bold"/>
                        </div>
                      </div>
                    </div>
                    <div className='pb-10'>
                      <h1 className='font-bold pb-4'>Location</h1>
                      <div className=''>
                        <LoadScript
                            googleMapsApiKey="AIzaSyAE72lXp2-ShkmBra1jqCfkhNCqFAGNhJQ"
                        >
                          <GoogleMap
                              mapContainerStyle={containerStyle}
                              center={center}
                              zoom={10}
                          >
                            <Marker position={center}/>
                          </GoogleMap>
                        </LoadScript>
                      </div>
                    </div>

                    <div className='pb-10'>
                      <h1 className='font-bold pb-4'>Amenities</h1>
                      <div className='flex overflow-hidden flex-wrap gap-x-1 md:gap-x-0 gap-y-3.5'>
                        {
                          propertyDetails?.property?.allamenity.map((feature: any, index: number) => {
                            return (
                                <div key={index}
                                     className='md:w-1/3 lg:w-1/4 xl:w-1/5 flex items-center gap-1.5 md:gap-3'>
                                  <Mark/>
                                  <p className='font-semibold text-xxs lg:text-xs xl:text-sm'>{feature.details?.title}</p>
                                </div>
                            );
                          })
                        }
                      </div>
                    </div>
                  </div>
                </>
          }
        </div>
          </div> :
          <BuyProperty investmentDetails={datata.current} onHide={() => setBuy(false)}/>}
    </div>
  );
}

export default Viewproperty;
