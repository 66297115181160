import { postLogin } from '../services/authservices';
import { Login } from '../constant/serviceType';
import { NavigateFunction } from 'react-router-dom';

export const loginUser = async (payload: Login, navigate: NavigateFunction) => {
  let userResponse = null;
  try {
    const response = await postLogin(payload);
    // console.log('registerUser', response);
    userResponse = response?.data?.data;
    if (userResponse) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const access_token = 'verified';
      navigate('/app/dashboard');
      return { success: true, data: access_token, user: userResponse };
    }
  } catch (e: any) {
    // console.log(e.response.data.message);
    return { success: false, error: e.response.data.message };
  }

  return userResponse;
};
