import { Icon } from '../../Services/constant/serviceType';

export const CoOwnIcon = ({ size, color }: Icon) => {
  return (
      <>
          <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_3813_16495)">
                  <path
                      d="M17.2605 6.5675L11.7053 2.25065C11.2178 1.87174 10.6177 1.66602 10.0001 1.66602C9.38242 1.66602 8.78238 1.87174 8.29487 2.25065L2.73862 6.5675C2.40471 6.82693 2.13455 7.15917 1.94879 7.53884C1.76302 7.91851 1.66655 8.33558 1.66675 8.75819V16.2513C1.66675 16.8033 1.88624 17.3327 2.27694 17.7231C2.66764 18.1134 3.19755 18.3327 3.75008 18.3327H16.2501C16.8026 18.3327 17.3325 18.1134 17.7232 17.7231C18.1139 17.3327 18.3334 16.8033 18.3334 16.2513V8.75819C18.3334 7.90169 17.9376 7.09306 17.2605 6.5675Z"
                      stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
              <defs>
                  <clipPath id="clip0_3813_16495">
                      <rect width={size} height={size} fill="white"/>
                  </clipPath>
              </defs>
          </svg>
      </>
  );
};
