import React, { useEffect, useState } from 'react';
import { ArrowCircleLeft2 } from 'iconsax-react';
// import { ReactComponent as Rating } from '../../Assets/Icons/starrating.svg';
import InputField from '../Form/InputField';
import ReviewTransaction from './ReviewTransaction';
import { formatCurrency } from '../../Utils/currencyFormat';
import { usePayment } from '../../Services/hooks/payment';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../Storeredux/reducers/rootReducer';
import { userData } from '../../Storeredux/constants/actionTypes';
import { PostPayment } from '../../Services/constant/serviceType';
import CustomButton from '../Form/CustomButton';
import PaymentFrame from '../Modal/PaymentFrame';
import { dateFormat } from '../../Utils/dateFormat';

function BuyProperty(props: any) {
  const location = useLocation();
  let pageName = location.pathname;
  pageName = pageName.split('/').pop() || '';
  const UserState = useSelector((state: RootState) => state.user as userData);
  const { user } = UserState;
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setError] = useState<string | null>(null);
  const { loading, verifyPayment, postPayWithWallet } = usePayment();
  const [amount, setAmount] = useState<number>(props.investmentDetails?.property?.total_investment_amount || 0);
  const [slot, setSlot] = useState<number>(0);
  const [review, setReview] = useState(false);
  const handleAmountChange = (e: any) => {
    console.log(props.investmentDetails.results.property.available_slots);
    if ( e.target.value > props.investmentDetails.results.property.available_slots) {
      setError('Number grater than available slots');
    } else {
      setError('');
      setSlot(e.target.value);
      const price : any = e.target.value * props.investmentDetails.results.property.total_investment_amount /
          props.investmentDetails.results.property.total_slots || 0;
      setAmount(price);
    }

  };
  const handleSuggestSlotChange = (newSuggestSlot: number) => {
    console.log(props.investmentDetails.results.property.available_slots);
    if ( newSuggestSlot > props.investmentDetails.results.property.available_slots) {
      setError('Number grater than available slots');
    } else {
      setError('');
      setSlot(newSuggestSlot);
      const price : any = newSuggestSlot * props.investmentDetails.results.property.total_investment_amount /
            props.investmentDetails.results.property.total_slots || 0;
      setAmount(price);
    }
  };
  const navigate = useNavigate();
  const [reviewData, setReviewData] = useState({});
  const [responseIframe] = useState<any>(null);
  const handleSubmit = async () => {
    // e.preventDefault();
    console.log(pageName);
    const data: PostPayment = {
      'slot': slot,
      'amount': props.investmentDetails?.results?.property?.total_investment_amount,
      // 'total_amount': amount,
      'total_amount': 5000,
      'user_id': user.id,
      'property_id' : Number(pageName),
      // 'property_id': 2,

    };
    const response = await postPayWithWallet(data);
    console.log('tyu', response);


  };
  const handleVerifyPayment = async () => {
    const response = await verifyPayment(responseIframe?.results.reference);
    console.log(response);
    if (response) {
      navigate('/app/myProperties');
    }
  };
  const handleReview = () => {
    setReview(true);
    const data: PostPayment = {
      'slot': slot,
      'amount': props.investmentDetails?.results?.property?.total_investment_amount,
      'total_amount': amount,
      // 'total_amount': 5000,
      'user_id': user.id,
      'property_id' : Number(pageName),

    };
    setReviewData(data);

  };
  useEffect(() => {
    if (props.investmentDetails) {
      setAmount(props.investmentDetails.results.property.total_investment_amount /
          props.investmentDetails.results.property.total_slots || 0);
    }
  }, []);
  return (
      <div>
        <div className='w-11/12 mx-auto'>
          <div className='flex items-center gap-2 py-4 cursor-pointer'>
            <ArrowCircleLeft2 size="42" variant="Bold" color="#20996B" onClick={() => props.onHide()}/>
          </div>
          {!review ?
              <div className='lg:w-9/12 xl:w-9/12 rounded-3xl bg-white mx-auto py-6 px-4 md:px-14 flex md:flex-row flex-col justify-between'>
                <div className=' w-full md:w-4/12'>
                  <h1 className='font-bold pb-3 text-xl '>
                    Price
                  </h1>
                  <div
                      className={'bg-custom-darkgreen rounded-2xl w-full text-center py-4'}>
                    <span className='text-white font-semibold'>Property Value</span>
                    <p className='font-bold text-white text-2xl py-6 px-4'>{formatCurrency(props.investmentDetails?.results?.property?.total_investment_amount)}</p>
                    {/*<p className='text-white font-semibold text-sm'>Annual Return:₦6,000.000.00</p>*/}
                  </div>
                  <div className='pt-5 md:block hidden'>
                    <h1 className='font-bold text-xl py-5'>Co-Owners History</h1>
                    <div>
                      { props.investmentDetails?.results?.investors === 'No investors yet' ?
                        <div>
                        <p className={'text-custom text-center'}>No previous Owner</p>
                      </div> :
                          <div>
                            {
                              props.investmentDetails?.results?.investors.map((history: any, index: number) => {
                                return (
                                    <div key={index} className='flex gap-4'>
                                      <div>
                            <span className='block h-8 w-7 bg-custom-white rounded-xl'>
                            </span>
                                        <div
                                            className={`bg-white ${index + 1 === props.investmentDetails?.results?.investors?.length ? 'hidden' : ''}`}>
                                          {Array.from({ length: 5 }).map((_, number) => (
                                              <hr key={number}
                                                  className={'h-0.5 w-1.5 mx-auto rounded border-0 horizontal-to-vertical my-3 bg-custom-white '}/>
                                          ))}
                                        </div>
                                      </div>
                                      <div>
                                        <h1 className='font-semibold'>{history?.investor?.fullname}</h1>
                                        <p className='text-custom text-xs'>Purchased At {dateFormat(history?.slots[0]?.created_at)}</p>
                                        <p className='text-custom text-xs'>Slot ID: {history?.slots[0]?.slots}</p>
                                        {/*<p className='flex gap-1.5'>*/}
                                        {/*  {Array.from({ length: 5 }).map((_, rate) => (*/}
                                        {/*    <Rating key={rate}/>*/}
                                        {/*  ))}*/}
                                        {/*</p>*/}
                                      </div>
                                    </div>
                                );
                              })
                            }
                          </div>
                      }
                    </div>
                  </div>
                </div>
                <div className=' flex flex-col items-center py-9 '>
                <div className='py-9 px-10 rounded-3xl' style={{ boxShadow: '0px 4px 20px 0px #5E84C20F' }}>
                    <h1 className='text-2xl font-bold py-4'>
                      How many slots are you buying?
                    </h1>
                    <div className=''>
                  <span className='text-sm'>
                    Suggested for you
                  </span>
                      <div className='flex gap-3 my-3'>
                        <button type='button' className='rounded-full py-2 px-6 text-xs'
                                onClick={() => handleSuggestSlotChange(1)}
                                style={{ backgroundColor: '#C0F0FF' }}>
                          1 Slot
                        </button>
                        <button type='button' className='rounded-full py-2 px-6 text-xs'
                                onClick={() => handleSuggestSlotChange(2)}
                                style={{ backgroundColor: '#C0F0FF' }}>
                          2 Slots
                        </button>
                        <button type='button' className='rounded-full py-2 px-6 text-xs'
                                onClick={() => handleSuggestSlotChange(3)}
                                style={{ backgroundColor: '#FADAFF' }}>
                          3 Slots
                        </button>
                        <button type='button' className='rounded-full py-2 px-6 text-xs'
                                onClick={() => handleSuggestSlotChange(4)}
                                style={{ backgroundColor: '#D1FFE1' }}>
                          4 Slots
                        </button>
                      </div>
                      <p className='text-sm py-3 font-bold'>Price Per Slot {
                          formatCurrency(props.investmentDetails?.results?.property?.total_investment_amount &&
                          props.investmentDetails?.results?.property?.total_slots
                            ? props.investmentDetails.results.property.total_investment_amount /
                              props.investmentDetails.results.property.total_slots
                            : 'N/A' )
                        }
                      </p>
                      <form onSubmit={handleReview}>
                        <div className='py-5'>
                          <div className='py-3'>
                            <InputField name='Enter Slot' type='text' className='w-3/4' value={slot}
                                        onChange={handleAmountChange}/>
                            <p className={'text-red-900 italic text-sm py-1 '}>{errorMessage}</p>
                          </div>
                          <div className='py-3'>
                            <InputField name='Amount' readOnly={true} type='text' className='w-full'
                                        value={formatCurrency(amount)}/>
                          </div>
                        </div>
                        <div className='my-4'>
                        {/*<button type='button'*/}
                          {/*  disabled={slot === 0}*/}
                          {/*  className={`${slot ? '' : 'opacity-60'} bg-custom-midnightgreen w-full py-3 text-white rounded-custom font-bold px-6 text-xs`}*/}
                          {/*  onClick={() => {*/}
                          {/*    setReview(true);*/}
                          {/*  }}>*/}
                          {/*    Continue*/}
                          {/*</button>*/}
                          <CustomButton
                              isLoading={loading}
                              disabled={!slot}
                              children={'Continue'}
                              className={`${slot ? '' : 'opacity-60'} bg-custom-midnightgreen w-full py-3 text-white rounded-custom font-bold px-6 text-xs`}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div> :
              <ReviewTransaction reviewData={reviewData} submit={handleSubmit}/>
          }
        </div>
        <div>
          <PaymentFrame  isOpen={isOpen} onHide={() => setIsOpen(false)} src={responseIframe?.results?.link} verify={handleVerifyPayment}/>
        </div>

      </div>
  );
}

export default BuyProperty;
