import { useRef, useState } from 'react';
import { User } from '../../Storeredux/constants/actionTypes';
import { checkPayment, getPaymentHistoryList, getWallet } from '../services/walletService';


export const useWallet = () => {
  const [investmentDetails, setInvestmentDetails] = useState<User>();
  const [loading, setLoading] = useState<boolean>(false);
  const didFetch = useRef<boolean>(false);
  const getPaymentHistory = async (id: string | number) => {
    if (didFetch.current) return;
    let userResponse = null;
    setLoading(true);
    try {
      const response = await getPaymentHistoryList(id);
      console.log('hitory', response);
      userResponse = response?.data?.data;
      if (userResponse) {
        setInvestmentDetails(userResponse);
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return { success: true, results: userResponse };
      }
    } catch (e: any) {
      console.log(e.response.data.message);
      setLoading(false);
      return { success: false, error: e.response.data.message };
    }

    return userResponse;
  };
  const getWalletData = async (id: string | number) => {
    if (didFetch.current) return;
    let userResponse = null;
    setLoading(true);
    try {
      const response = await getWallet(id);
      console.log('WALLET', response);
      userResponse = response?.data?.data;
      if (userResponse) {
        setInvestmentDetails(userResponse);
        const responseHistory: any = await getPaymentHistory(userResponse.id);
        console.log('gfgghh', responseHistory);
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return { success: true, results: userResponse, history: responseHistory?.results };
      }
    } catch (e: any) {
      console.log(e.response.data.message);
      setLoading(false);
      return { success: false, error: e.response.data.message };
    }

    return userResponse;
  };
  const checkTransaction = async (id: string | number, date: string) => {
    if (didFetch.current) return;
    let userResponse = null;
    setLoading(true);
    try {
      const response = await checkPayment(id, date);
      console.log('WALLET', response);
      userResponse = response?.data?.data;
      if (userResponse) {
        setInvestmentDetails(userResponse);
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return { success: true, results: userResponse };
      }
    } catch (e: any) {
      console.log(e.response.data.message);
      setLoading(false);
      return { success: false, error: e.response.data.message };
    }

    return userResponse;
  };
  return { getWalletData, getPaymentHistory, checkTransaction, loading, investmentDetails };
};
