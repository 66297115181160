import React, { useState } from 'react';
import InputField from '../Form/InputField';

function ChangePassword() {
  const [data, setData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const onChange = (e: any, fieldKey: string) => {
    console.log('next', e.target.value, fieldKey);
    setData({ ...data, [fieldKey]: e.target.value });
  };
  return (
    <div className='p-10 flex flex-col gap-10'>
      <div className='flex w-full gap-5'>
        <InputField name='Old Password' fieldKey={'oldPassword'} type='password' value={data.oldPassword} onChange={onChange} className='w-full'/>
      </div>
      <div className='flex w-full gap-5'>
        <InputField name='New Password' fieldKey={'newPassword'} type='password' value={data.newPassword} onChange={onChange}  className='w-full'/>
      </div>
      <div className='flex w-full gap-5'>
        <InputField name='Confirm Pawword' type='password'  fieldKey={'confirmNewPassword'} value={data.confirmNewPassword} onChange={onChange}  className='w-full'/>
      </div>
      <div className='flex w-full gap-5'>
        <button className='bg-custom-midnightgreen text-white py-2 px-12 font-semibold rounded-custom border-0'>
                    Save Changes
        </button>
      </div>
    </div>
  );
}

export default ChangePassword;
