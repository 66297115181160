import { authorized } from '../axios';
import { CHECK_PAYMENT, GET_PAYMENT_HISTORY, GET_WALLET } from '../service-routes';

export const getWallet = (id: string | number) => {
  return authorized.get(`${GET_WALLET}/${id}`);
};

export const getPaymentHistoryList = (id: string | number) => {
  return authorized.get(`${GET_PAYMENT_HISTORY}/${id}`);
};

export const checkPayment = (id: string | number, date: string) => {
  return authorized.get(`${CHECK_PAYMENT}/${date}/${id}`);
};

